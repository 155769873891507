import {AnyAction} from 'redux'
import {GET_COMPONENT_DATA} from '../actions/component'
import {Events} from '../types/state'
import {detailedEvents} from './events'

const defaultState: Events = {
  past: [],
  upcoming: [],
  total: {
    past: 0,
    upcoming: 0,
  },
}

export const demoEvents = (state = defaultState, action: AnyAction): Events => {
  switch (action.type) {
    case GET_COMPONENT_DATA.SUCCESS:
      return getComponentDataSuccess(state, action)
    default:
      return state
  }
}

const getComponentDataSuccess = (state: Events, action: AnyAction): Events => {
  if (action.payload.demoData) {
    const {events, orders, rsvps} = action.payload.demoData
    if (events) {
      const past = [...state.past, ...detailedEvents(events, orders, rsvps)]
      const upcoming = [...state.upcoming, ...detailedEvents(events, orders, rsvps)]

      return {
        past,
        upcoming,
        total: {
          past: past.length,
          upcoming: upcoming.length,
        },
      }
    }
  }

  return state
}
