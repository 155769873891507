import {AnyAction} from 'redux'
import {Tabs} from '../../../commons/enums'
import {SET_TAB} from '../actions/events'

const defaultState: Tabs = Tabs.UPCOMING

export const tab = (state = defaultState, action: AnyAction): Tabs => {
  switch (action.type) {
    case SET_TAB:
      return action.payload
    default:
      return state
  }
}
