import {AnyAction} from 'redux'
import {EventsList, Tabs} from '../../../commons/enums'
import {GET_EVENTS} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'
import {DetailedEvent, Events} from '../types/state'

const defaultState: Events = {
  upcoming: [],
  past: [],
  total: {
    past: 0,
    upcoming: 0,
  },
}

export const eventsReducer = (state = defaultState, action: AnyAction): Events => {
  switch (action.type) {
    case GET_EVENTS.SUCCESS:
      return getEventsSuccess(state, action)
    case CANCEL_RSVP.SUCCESS:
    case DELETE_RSVP.SUCCESS:
      return deleteRSVPSuccess(state, action)
    default:
      return state
  }
}

const getEventsSuccess = (state: Events, action: AnyAction): Events => {
  const {events, orders, rsvps} = action.payload
  const {tab} = action.args[0]

  const key = tab === Tabs.PAST ? EventsList.PAST : EventsList.UPCOMING

  return {
    ...state,
    [key]: [...state[key], ...detailedEvents(events, orders, rsvps)],
    total: {
      ...state.total,
      [key]: action.payload.total,
    },
  }
}

const deleteRSVPSuccess = (state: Events, action: AnyAction): Events => ({
  ...state,
  upcoming: state.upcoming.filter(event => event.event.id !== action.payload.eventId),
  total: {
    ...state.total,
    upcoming: state.total.upcoming > 0 ? state.total.upcoming - 1 : state.total.upcoming,
  },
})

export const detailedEvents = (
  events: wix.events.Event[],
  orders: wix.events.ticketing.Order[],
  rsvps: wix.events.rsvp.Rsvp[],
): DetailedEvent[] =>
  events.map(event => ({
    event,
    orders: orders ? orders.filter(order => order.eventId === event.id) : [],
    rsvp: rsvps ? rsvps.find(rsvp => rsvp.eventId === event.id) : null,
  }))
