import {AnyAction} from 'redux'
import {GET_SITE_SETTINGS, UPDATE_SITE_SETTINGS} from '../actions/site-settings'
import {SiteSettings} from '../types/state'

const defaultState: SiteSettings = {} as SiteSettings

export const siteSettings = (state = defaultState, action: AnyAction): SiteSettings => {
  switch (action.type) {
    case GET_SITE_SETTINGS.SUCCESS:
    case UPDATE_SITE_SETTINGS.SUCCESS:
      return action.payload.settings
    default:
      return state
  }
}
